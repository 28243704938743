const HomeWelcome = () => {
  return (
    <div id="welcome" className="hero is-light is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container ml-0">
          <h2 className="title m-1 is-1 is-size-3-touch">Hallo, ich bin Pip</h2>
          <h1 className="subtitle m-1 is-3 is-size-5-touch">
            Web-Analyst & Hobby-Programmierer
          </h1>
          <h2 className="subtitle mx-2 my-3 is-5 is-size-6-touch is-italic">
            Für{" "}
            <span className="tag is-radiusless is-info is-small mb-1">
              Analytics
            </span>{" "}
            , <span className="tag is-radiusless is-danger mb-1">Coding</span> ,{" "}
            <span className="tag is-radiusless is-dark mb-1">Filme</span> aus
            den 80ern und vor allem{" "}
            <span className="tag is-radiusless is-success mb-1">Pizza</span> bin
            ich immer zu haben
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HomeWelcome;
