const HomeAbout = () => {
  return (
    <div id="about" className="hero is-hero is-medium is-light">
      <div className="is-wrapper">
        <div className="hero-head">
          <div className="container has-text-centered">
            <h2 className="title is-3 is-size-4-touch">Über mich</h2>
            <h2 className="subtitle is-5 is-size-6-touch is-italic">
              Was ich in den letzten Jahren gemacht und gelernt habe
            </h2>
          </div>
        </div>
        <div className="hero-body">
          <div className="columns is-desktop is-centered">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <h3 className="title is-4">Erfahrung</h3>
                  <hr />
                  <div className="content">
                    <table className="table is-fullwidth">
                      <thead>
                        <tr>
                          <td>Zeit</td>
                          <td>Position</td>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          {
                            time: `${Math.round(
                              new Date().getFullYear() - 1 - 2019
                            )} Jahre`,
                            role: "Digital Analyst",
                            company: "Audi Business Innovation",
                            tools:
                              "Adobe Analytics, Ensighten Tag, Ensighten Privacy, ...",
                            link: "https://www.audibusinessinnovation.com/",
                          },
                          {
                            time: "4 Jahre",
                            role: "Digital Analytics Consultant",
                            company: "AT Internet",
                            tools:
                              "AT Internet Suite, GTM, TealiumIQ, TagCommander, ...",
                            link: "https://www.atinternet.com/",
                          },
                          {
                            time: "2 Jahre",
                            role: "Junior Account Manager",
                            company: "plan.net Performance",
                            tools:
                              "Google Ads, Google Ads Scripts, Bing Ads, YouTube Ads, ...",
                            link: "https://www.plan-net.com/",
                          },
                          {
                            time: "7 Jahre",
                            role: "Werkstudent, externer Mitarbeiter",
                            tools: "Google Ads, Magento, JTL-Wawi, ...",
                            company: "Reishunger",
                            link: "https://www.reishunger.de/",
                          },
                        ].map((item) => {
                          return (
                            <tr key={item.company}>
                              <td className="py-4">{item.time}</td>
                              <td className="py-4">
                                {item.role}
                                {" @ "}
                                <a
                                  className="has-text-info"
                                  href={item.link}
                                  target="_blank"
                                >
                                  {item.company}
                                </a>
                                <br></br>
                                <span className="is-italic has-text-grey is-size-7">
                                  {item.tools}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <h3 className="title is-4">Interessen</h3>
                  <hr />
                  <div className="content">
                    <div className="tags">
                      {[
                        { name: "Analytics", style: "is-info" },
                        { name: "API's", style: "is-danger" },
                        { name: "Automation", style: "is-danger" },
                        { name: "AWS", style: "is-danger" },
                        { name: "Browser Extensions", style: "is-danger" },
                        { name: "Data Science", style: "is-info" },
                        { name: "Filme", style: "is-dark" },
                        { name: "Hunde", style: "" },
                        { name: "Node.js", style: "is-danger" },
                        { name: "Pizza & Brot backen", style: "is-success" },
                        { name: "Python", style: "is-danger" },
                        { name: "Raspberry Pi", style: "is-danger" },
                        { name: "React", style: "is-danger" },
                        { name: "Tag Mangement", style: "is-danger" },
                        { name: "TypeScript", style: "is-danger" },
                        { name: "Wandern", style: "" },
                        { name: "Web Crawling", style: "is-danger" },
                      ].map((item) => {
                        return (
                          <span
                            key={item.name}
                            className={`tag is-radiusless ${item.style}`}
                          >
                            {item.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card">
                <div className="card-content skills-content">
                  <h3 className="title is-4">Fähigkeiten</h3>
                  <div className="content">
                    {[
                      {
                        name: "Digital Analytics Tools",
                        value: "85",
                        type: "Analytics",
                        style: "is-info",
                      },
                      {
                        name: "Tag Management Systeme",
                        value: "85",
                        type: "Coding",
                        style: "is-danger",
                      },
                      {
                        name: "JavaScript und TypeScript",
                        value: "70",
                        type: "Coding",
                        style: "is-danger",
                      },
                      {
                        name: "Python",
                        value: "60",
                        type: "Coding",
                        style: "is-danger",
                      },
                      {
                        name: "Backen",
                        value: "55",
                        type: "Pizza",
                        style: "is-success",
                      },
                    ].map((item) => {
                      return (
                        <div key={item.name}>
                          <hr />
                          <div className="block">
                            <span>
                              {item.name} –
                              <span
                                className={`tag is-radiusless ${item.style} m-2`}
                              >
                                {item.type}
                              </span>
                            </span>
                            <progress
                              className="progress is-black is-small mb-2"
                              value={item.value}
                              max="100"
                            ></progress>
                            <span className="is-italic has-text-grey is-size-7">
                              Anfänger
                            </span>
                            <span className="is-italic has-text-grey is-size-7 is-pulled-right">
                              Experte
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-end"></div>
    </div>
  );
};

export default HomeAbout;
