import logo from "../assets/images/logo.png";

const Head = () => {
  const toggleMenu = () => {
    document.querySelector(".navbar-burger").classList.toggle("is-active");
    document.querySelector(".burger-menu").classList.toggle("is-active");
  };

  const scrollTo = (element) => {
    toggleMenu();
    const id = element.target.dataset.target;
    const yOffset = 0; // -52 with sticky navbar
    const target = document.querySelector(id);
    const y = target.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <nav className="navbar is-menu">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={logo} alt="Logo" />
        </a>
        <div className="navbar-burger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="navbar-menu burger-menu p-0">
        <div className="navbar-end">
          <a
            className="navbar-item"
            href="#projects"
            data-target="#projects"
            onClick={scrollTo}
          >
            Meine Projekte
          </a>
          <hr className="m-0" />
          <a
            className="navbar-item"
            href="#about"
            data-target="#about"
            onClick={scrollTo}
          >
            Über Mich
          </a>
          <hr className="m-0" />
          <a
            className="navbar-item"
            href="#contact"
            data-target="#contact"
            onClick={scrollTo}
          >
            Kontakt
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Head;
