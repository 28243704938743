const Load = () => {
  return (
    <div className="hero is-hero is-fullheight">
      <div className="hero-body is-justify-content-center">
        <button className="button is-large is-loading">Loading</button>
      </div>
    </div>
  );
};

export default Load;
