import Tracking from "../services/tracking";

const HomeProjects = ({ projects }) => {
  const handleClick = (event) => {
    const target = event.target.dataset.target;
    Tracking.sendAction(target, "button", "exit");
  };

  return (
    <div id="projects" className="hero is-hero is-medium">
      <div className="is-wrapper">
        <div className="hero-head">
          <div className="container has-text-centered">
            <h2 className="title is-3 is-size-4-touch">Meine Projekte</h2>
            <h2 className="subtitle is-5 is-size-6-touch is-italic">
              Ein paar Basteleien, die es auf einen vorzeigbaren Stand gebracht
              haben
            </h2>
          </div>
        </div>
        <div className="hero-body">
          <div className="columns is-centered is-flex-direction-column is-align-items-stretch">
            {projects.map((project) => {
              return (
                <div key={project.sys.id} className="column is-project">
                  <div className="section has-text-centered">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image is-16by9">
                          <a
                            href={project.fields.link}
                            target="_blank"
                            rel="noreferrer"
                            data-target={project.fields.title}
                            onClick={handleClick}
                          >
                            <img
                              className="is-image"
                              src={project.fields.thumbnail.fields.file.url}
                              alt="Description"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="column is-6">
                        <h1 className="title is-3 is-size-4-touch">
                          {project.fields.title}
                        </h1>
                        <h2 className="subtitle is-5 is-size-6-touch has-text-grey">
                          {project.fields.category}
                          {" – "}
                          {project.fields.tags.map((tag) => {
                            const [name, style] = tag.split(":");
                            return (
                              <span
                                key={name}
                                className={`tag is-radiusless ${style} mr-1`}
                              >
                                {name}
                              </span>
                            );
                          })}
                        </h2>
                        <p>{project.fields.description}</p>
                        <br />
                        <p className="has-text-centered">
                          <a
                            href={project.fields.link}
                            target="_blank"
                            rel="noreferrer"
                            className="button is-outlined is-radiusless"
                            data-target={project.fields.title}
                            onClick={handleClick}
                          >
                            Zur {project.fields.category}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="hero-end"></div>
    </div>
  );
};

export default HomeProjects;
