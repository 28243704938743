import Load from "./Load";
import Error from "./Error";
import HomeAbout from "./HomeAbout";
import HomeContact from "./HomeContact";
import HomeProjects from "./HomeProjects";
import HomeWelcome from "./HomeWelcome";
import { useState, useEffect } from "react";
import { getEntries } from "../services/content";
import Tracking from "../services/tracking";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getEntries((error, data) => {
      if (error) {
        setError(error);
      } else {
        setData(data);
      }
      setLoading(false);
    });
    Tracking.sendView("home", "/", "");
  }, []);

  if (loading) {
    return <Load />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (data) {
    return (
      <>
        <HomeWelcome />
        <HomeProjects projects={data} />
        <HomeAbout />
        <HomeContact />
      </>
    );
  }
};

export default Home;
