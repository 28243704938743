const ErrorMessage = (props) => {
  return (
    <>
      <h2 className="title is-3 is-size-4-touch">Fehler</h2>
      <p>{props.error.message}</p>
      <br />
      <p>
        <a href="mailto:p.a.jaeckle@gmail.com">Informiere mich</a> über den
        Fehler oder versuche es später erneut.
      </p>
    </>
  );
};

export default ErrorMessage;
