import ErrorMessage from "./ErrorMessage";

const Error = (props) => {
  return (
    <div className="hero is-hero is-fullheight">
      <div className="is-wrapper-small">
        <div className="hero-body is-justify-content-center">
          <div className="notification">
            <ErrorMessage error={props.error} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
