import "bulma/css/bulma.min.css";
import "./assets/css/custom.css";
import Head from "./components/Head";
import Home from "./components/Home";
import Foot from "./components/Foot";
import Tracking from "./services/tracking";

const App = () => {
  Tracking.initiate();
  return (
    <div className="App">
      <Head />
      <Home />
      <Foot />
    </div>
  );
};

export default App;
