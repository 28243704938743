import * as contentful from "contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const client = contentful.createClient({
  space: `${process.env.REACT_APP_SPACE_ID}`,
  accessToken: `${process.env.REACT_APP_ACCESS_TOKEN}`,
});

const getEntries = (callback) => {
  client
    .getEntries({
      content_type: "datapip",
      order: "fields.order",
    })
    .then((entries) => {
      callback(null, entries.items);
    })
    .catch((error) => {
      callback(error, null);
    });
};

const getEntry = (path, callback) => {
  client
    .getEntries({
      content_type: "legal",
      "fields.path": path,
    })
    .then((entries) => {
      callback(null, entries.items.pop());
    })
    .catch((error) => {
      callback(error, null);
    });
};

const parseEntry = (content) => {
  console.log(content);
  return documentToHtmlString(content);
};

export { getEntries, getEntry, parseEntry };
