import { Buffer } from "buffer";

const Tracking = {
  apiDomain: "https://api.datapip.de",
  apiVersion: "v1",
  apiPath: "c",
  apiParams: "",
  clientId: "",
  hitDepth: "",
  currentPage: "",
  customElements: {
    siteSections: [
      {
        name: "projects",
        selector: "#projects div.hero-end",
        sent: false,
      },
      {
        name: "about",
        selector: "#about div.hero-end",
        sent: false,
      },
      {
        name: "contact",
        selector: "#contact div.hero-end",
        sent: false,
      },
    ],

    inViewport: function (selector) {
      const element = document.querySelector(selector);
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      const headerHeight = document.querySelector(".is-menu").clientHeight;
      const visibleHeight = document.documentElement.clientHeight;
      return rect.top > headerHeight && rect.top < visibleHeight;
    },

    scrollTracking: function (parent) {
      window.addEventListener("scroll", () => {
        this.siteSections.map((section) => {
          if (!section.sent && this.inViewport(section.selector)) {
            parent.sendView(section.name, `/#${section.name}`, "");
            window.removeEventListener("scroll", section.scrollHandler);
            section.sent = true;
          }
        });
      });
    },
  },

  initiate: function () {
    window.dataLayer = window.dataLayer || [];
    this.clientId = this._createId();
    this.hitDepth = 1;
    this.apiParams = this._setParams();
    this.customElements.scrollTracking(this);
  },

  _setDebug: function () {
    const params = new URLSearchParams(document.location.search);
    const debugValue = params.get("debug");
    if (debugValue) window.localStorage.setItem("debug", debugValue);
  },

  _setParams: function () {
    this._setDebug();
    const isDebug = window.localStorage.getItem("debug");
    if (!isDebug) return "";
    return `?debug=${isDebug}`;
  },

  _createId: function () {
    const randomId = () => Math.floor(Math.random() * 10000000000);
    return `${randomId()}.${randomId()}`;
  },

  _createClient: function () {
    return {
      clientId: this.clientId,
      hitDepth: this.hitDepth,
      clientLanguage:
        window.navigator.userLanguage || window.navigator.language,
      clientResolution: `${window.screen.width}x${window.screen.height}`,
    };
  },

  _createPage: function (name, id, category) {
    this.currentPage = {
      site: document.location.hostname,
      pageName: name.toLowerCase(),
      pageId: id.toLowerCase(),
      pageCategory: category.toLowerCase(),
      destinationURL: document.location.href,
      referringURL: document.referrer,
    };
    return this.currentPage;
  },

  _updateDepth: function () {
    this.hitDepth++;
  },

  _toBase64: function (string) {
    return Buffer.from(string).toString("base64");
  },

  _createRequest: function (data) {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: this._toBase64(JSON.stringify(data)),
    };
    fetch(
      `${this.apiDomain}/${this.apiVersion}/${this.apiPath}${this.apiParams}`,
      options
    );
  },

  sendView: function (name, id, category) {
    const payload = {
      event: "view",
      client: this._createClient(),
      page: this._createPage(name, id, category),
    };
    this._createRequest(payload);
    window.dataLayer.push(payload);
    this._updateDepth();
  },

  sendAction: function (name, type, effect) {
    const payload = {
      event: "action",
      client: this._createClient(),
      page: this.currentPage,
      action: {
        actionName: name.toLowerCase(),
        actionType: type.toLowerCase(),
        actionEffect: effect.toLowerCase(),
      },
    };
    this._createRequest(payload);
    window.dataLayer.push(payload);
    this._updateDepth();
  },
};

export default Tracking;
