import { useState, useEffect } from "react";
import { getEntry, parseEntry } from "../services/content";
import Load from "./Load";
import ErrorMessage from "./ErrorMessage";
import Tracking from "../services/tracking";

const Modal = (props) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getEntry(props.content, (error, data) => {
      if (error) {
        setError(error);
      } else {
        setContent(data.fields.content);
      }
      setLoading(false);
    });
    Tracking.sendView(props.content, `/${props.content}`, "");
  }, []);

  return (
    <div className="modal is-modal is-active">
      <div
        className="modal-background"
        data-target="delete"
        onClick={props.handler}
      ></div>
      <div className="modal-content">
        <button
          className="delete is-large p-0"
          data-target="delete"
          onClick={props.handler}
        ></button>
        <div className="card">
          <div className="card-content">
            {loading && <Load />}
            {error && <ErrorMessage error={error} />}
            {content && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: parseEntry(content) }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
