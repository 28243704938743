import mail from "../assets/images/mail-icon.png";
import xing from "../assets/images/xing-icon.png";
import linkedin from "../assets/images/linkedin-icon.png";
import Tracking from "../services/tracking";

const HomeContact = () => {
  const handleClick = (event) => {
    const target = event.target.dataset.target;
    Tracking.sendAction(target, "button", "exit");
  };

  return (
    <div id="contact" className="hero is-hero">
      <div className="is-wrapper">
        <div className="hero-head">
          <div className="container has-text-centered">
            <h2 className="title is-3 is-size-4-touch">Kontakt</h2>
            <h2 className="subtitle is-5 is-size-6-touch is-italic">
              Fragen, Anregungen, Kritik - immer her damit
            </h2>
          </div>
        </div>
        <div className="hero-body">
          <div className="columns is-contacts is-mobile is-centered">
            {[
              { name: "mail", src: mail, link: "mailto:p.a.jaeckle@gmail.com" },
              {
                name: "xing",
                src: xing,
                link: "https://www.xing.com/profile/Philipp_Jaeckle2/cv",
              },
              {
                name: "linkedin",
                src: linkedin,
                link: "https://www.linkedin.com/in/philipp-j%C3%A4ckle-296052b9/",
              },
            ].map((item) => {
              return (
                <div key={item.name} className="column has-text-centered">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleClick}
                  >
                    <img
                      width="64"
                      height="64"
                      src={item.src}
                      data-target={item.name}
                      alt={`${item.name} logo`}
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="hero-end"></div>
    </div>
  );
};

export default HomeContact;
